
import {
    defineComponent, ref, reactive, computed
} from 'vue';
import { pmCommonApi } from '@/api';
import List from '@/components/common/list/src/list.vue';

export default defineComponent({
    components: { List },
    expose: ['dialogVisible', 'title'],
    props: {
        logUUID: {
            type: String,
            default: ''
        },
        lockType: {
            type: Number,
            default: 0
        }
    },
    setup(props) {
        const dialogVisible = ref(false);
        // 表格title
        const title = ref('');
        const updateData = ref(0);
        // 表格column
        const column = [{
            name: 'RelayName', label: WordList.DeviceLocation
        }, {
            name: 'Response', label: WordList.Response
        }, {
            name: 'Status', label: WordList.Details, type: 'customize'
        }];
        const statusText = reactive({
            1: '--',
            2: computed(() => (props.lockType === 0 ? WordList.LockFailed : WordList.UnlockFailed)),
            3: WordList.deviceIsOffline,
            4: WordList.DeviceNotSupported,
            5: WordList.ProperAllTextTimeOut
        });
        // 后端返回值替代（后续再补充类型）
        const result = reactive<{
            tableData: {
                RelayName: string;
                Status: number;
                Response: string;
            }[];
            complete: number;
            failed: number;
            isFinish: 1|0;
        }>({
            isFinish: 0,
            tableData: [],
            complete: 0,
            failed: 0
        });

        function initResult() {
            result.isFinish = 0;
            result.tableData = [];
            result.complete = 0;
            result.failed = 0;
            updateData.value += 1;
        }
        initResult();

        // 结果是不断轮询获取的，所以loading
        const loading = ref(true);
        // @ts-ignore
        let timer: NodeJS.Timer|null = null;
        function clearTimer() {
            // @ts-ignore
            clearInterval(timer as NodeJS.Timer);
        }
        function getPmEmergencyDoorLogApi() {
            loading.value = true;
            timer = setInterval(() => {
                pmCommonApi.getPmEmergencyDoorLog({ LogUUID: props.logUUID }, (res: {
                    IsFinish: 1|0;
                    DeviceInfo: {
                        RelayName: string;
                        Status: number;
                        Response: string;
                    }[];
                }) => {
                    result.isFinish = res.IsFinish;
                    if (res.IsFinish === 1) {
                        clearTimer();
                        result.tableData = res.DeviceInfo;
                        // 总算
                        result.complete = result.tableData.length;
                        result.tableData.forEach((item, index) => {
                            if (item.Status === 1) {
                                result.tableData[index].Response = WordList.TabelChangePwStepSuccess;
                            } else {
                                // 记录失败数
                                result.failed += 1;
                                if (item.Status === 3) {
                                    result.tableData[index].Response = WordList.FailedOffline;
                                } else {
                                    result.tableData[index].Response = WordList.PropertyAllTextFailed;
                                }
                            }
                        });
                        updateData.value += 1;
                        loading.value = false;
                    }
                });
            }, 2000);
        }

        function close() {
            initResult();
            clearTimer();
            dialogVisible.value = false;
        }

        return {
            dialogVisible,
            title,
            column,
            result,
            loading,
            getPmEmergencyDoorLogApi,
            clearTimer,
            close,
            statusText,
            updateData
        };
    }
});
